import * as React from "react";

function LeadForecastForm(props) {
  return (
    <div className="flex flex-col items-center bg-white">
      <div className="flex overflow-hidden relative flex-col justify-center items-center self-stretch px-16 py-12 w-full min-h-[720px] max-md:px-5 max-md:max-w-full">
        <img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/9b8bc059c13b1444fcfb2fa46a89e369f2f12d9281dc461461705b68b781cb79?apiKey=73db546a11a0445f8ca73fcb518d621e&"
          className="object-cover object-center absolute inset-0 size-full"
        />
        <div className="flex relative gap-5 justify-between items-stretch mt-7 mb-96 w-full max-w-[1402px] max-md:flex-wrap max-md:mb-10 max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e9d6306bed7fc948e491e2f41f37a1edf7ccb261af23ff3a0b2a0da68e35d0b?apiKey=73db546a11a0445f8ca73fcb518d621e&"
            className="object-contain object-center shrink-0 self-start w-20 aspect-[1.59]"
          />
          <div className="flex gap-5 justify-between items-stretch my-auto max-md:flex-wrap max-md:max-w-full">
            <div className="flex gap-2 justify-between items-stretch px-px pt-2 pb-3.5">
              <div className="grow text-base text-center text-white whitespace-nowrap">
                MENU BUTTON
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5ad14ada49a4cf0974c50918adfd7ed18047a3df508c3fd8b96f48bcddd05817?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                className="object-contain object-center shrink-0 self-start mt-1.5 w-1.5 aspect-[2] fill-white"
              />
            </div>
            <div className="grow justify-center items-stretch px-1.5 pt-2 pb-3.5 text-base text-center text-white whitespace-nowrap">
              MENU BUTTON
            </div>
            <div className="grow justify-center items-stretch px-1.5 pt-2 pb-3.5 text-base text-center text-white whitespace-nowrap">
              MENU BUTTON
            </div>
            <div className="flex gap-2 justify-between items-stretch px-px pt-2 pb-3.5">
              <div className="grow text-base text-center text-white whitespace-nowrap">
                MENU BUTTON
              </div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5ad14ada49a4cf0974c50918adfd7ed18047a3df508c3fd8b96f48bcddd05817?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                className="object-contain object-center shrink-0 self-start mt-1.5 w-1.5 aspect-[2] fill-white"
              />
            </div>
            <div className="grow justify-center items-stretch px-1.5 pt-2 pb-3.5 text-base text-center text-white whitespace-nowrap">
              MENU BUTTON
            </div>
          </div>
          <div className="flex flex-col justify-center items-stretch basis-0">
            <div className="justify-center items-stretch px-5 py-5 text-xl font-bold text-center text-white border border-white border-solid leading-[72px] rounded-[57px]">
              XXX-XXX-XXXX
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-0 justify-between items-stretch self-stretch px-5 w-full max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink-0 bg-blue-900 h-[13px]" />
        <div className="flex flex-col flex-1 shrink-0 bg-indigo-500 h-[13px]" />
        <div className="flex flex-col flex-1 shrink-0 bg-amber-400 h-[13px]" />
        <div className="flex flex-col flex-1 shrink-0 bg-gray-900 h-[13px]" />
        <div className="flex flex-col flex-1 shrink-0 bg-indigo-500 h-[13px]" />
      </div>
      <div className="mt-20 text-5xl text-center text-indigo-500 max-md:mt-10 max-md:max-w-full max-md:text-4xl">
        <span className="text-black">Campaign</span>{" "}
        <span className="font-bold">Forecaster</span>
      </div>
      <div className="shrink-0 mt-5 w-64 max-w-full h-px bg-black" />
      <div className="mt-7 text-xl text-center text-black w-[1015px] max-md:max-w-full">
        {" "}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce non
        convallis velit. Mauris pulvinar, enim quis mattis mattis, tortor libero
        tincidunt neque, at mattis velit sapien ut odio. Nam et urna nulla.
      </div>
      <div className="mt-20 w-full max-w-[1477px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:items-stretch">
          <div className="flex flex-col items-stretch w-[39%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col items-stretch px-5 max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col items-stretch pl-10 max-md:pl-5 max-md:max-w-full">
                <div className="flex gap-5 items-stretch self-end max-w-full w-[454px] max-md:flex-wrap">
                  <div className="grow my-auto text-lg font-bold text-right text-black">
                    Name(s)*:
                  </div>
                  <div className="grow justify-center items-start py-3.5 pr-16 pl-3.5 text-xl border-b border-solid border-b-black text-black text-opacity-30 max-md:pr-5">
                    Name(s)
                  </div>
                </div>
                <div className="flex gap-5 justify-between items-stretch mt-7 max-md:flex-wrap max-md:max-w-full">
                  <div className="grow my-auto text-lg font-bold text-right text-black whitespace-nowrap">
                    Your Market*:
                  </div>
                  <div className="flex gap-5 justify-between items-stretch px-4 py-4 border-b border-solid border-b-black">
                    <div className="text-xl text-black text-opacity-30">
                      Market
                    </div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/9ebb38d513fb0c14c0b94c70a90d3a60fe75a4583ebf25ba919aa119edff5d7d?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                      className="object-contain object-center shrink-0 self-start mt-2 w-3 aspect-[2] fill-black"
                    />
                  </div>
                </div>
                <div className="flex gap-5 justify-between items-stretch mt-7 max-md:flex-wrap max-md:max-w-full">
                  <div className="grow my-auto text-lg font-bold text-right text-black whitespace-nowrap">
                    Website URL*:
                  </div>
                  <div className="grow justify-center items-start py-4 pr-16 pl-3.5 text-xl border-b border-solid border-b-black text-black text-opacity-30 max-md:pr-5">
                    Website
                  </div>
                </div>
                <div className="self-center mt-8 ml-5 text-xl text-black">
                  Budget
                </div>
              </div>
              <div className="flex gap-3 justify-between items-stretch mt-5 max-md:flex-wrap max-md:max-w-full">
                <div className="grow self-start mt-2.5 text-lg font-bold text-right text-black whitespace-nowrap">
                  Budget Goals*:
                </div>
                <div className="flex flex-col flex-1 items-center">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6ef87fc3-c0d2-46e6-90c8-3f139e6d9c2d?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                    className="object-contain object-center aspect-[11.11] stroke-[28px] stroke-stone-900 w-[371px]"
                  />
                  <div className="mt-4 text-base text-black">$7,000</div>
                </div>
              </div>
              <div className="self-center mt-9 text-xl text-black whitespace-nowrap">
                Goal Procedures
              </div>
              <div className="flex gap-3 justify-between items-stretch mt-6 max-md:flex-wrap max-md:max-w-full">
                <div className="grow my-auto text-lg font-bold text-right text-black whitespace-nowrap">
                  Goal Procedures*:
                </div>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/20d75445-6efd-4f95-a843-c1cf9d6d6c86?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                  className="object-contain object-center aspect-[10] stroke-[28px] stroke-stone-900 w-[350px]"
                />
              </div>
              <div className="flex flex-col items-stretch self-end px-px mt-4 max-w-full w-[423px]">
                <div className="self-center text-base text-black">50</div>
                <div className="justify-center items-stretch self-start px-14 py-5 mt-9 ml-5 text-xl font-bold text-center text-white whitespace-nowrap bg-gray-900 leading-[72px] rounded-[57px] max-md:px-5 max-md:ml-2.5">
                  Submit Forecast
                </div>
                <div className="flex gap-5 justify-between items-stretch mt-11 max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
                  <div className="grow self-end mt-7 text-lg font-bold text-right text-black">
                    Email*:
                  </div>
                  <div className="grow justify-center items-start py-4 pr-16 pl-3.5 text-xl whitespace-nowrap border-b border-solid border-b-black text-black text-opacity-30 max-md:pr-5">
                    Email Address
                  </div>
                </div>
              </div>
              <div className="flex gap-5 justify-between items-stretch mt-9 max-md:flex-wrap max-md:max-w-full">
                <div className="grow self-end mt-6 text-lg font-bold text-right text-black whitespace-nowrap">
                  Phone Number*:
                </div>
                <div className="grow justify-center items-start py-4 pr-16 pl-3.5 text-xl whitespace-nowrap border-b border-solid border-b-black text-black text-opacity-30 max-md:pr-5">
                  Phone Number
                </div>
              </div>
              <div className="justify-center items-stretch self-center px-5 py-5 mt-12 text-xl font-bold text-center text-black whitespace-nowrap border border-black border-solid leading-[72px] rounded-[57px] max-md:mt-10">
                Send Me The Full Report
              </div>
            </div>
          </div>
          <div className="flex flex-col items-stretch ml-5 w-[61%] max-md:ml-0 max-md:w-full">
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9dbce7b-2323-4429-9af9-00bbb76643dc?apiKey=73db546a11a0445f8ca73fcb518d621e&"
              className="object-contain object-center grow w-full aspect-square max-md:mt-10 max-md:max-w-full"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center self-stretch px-16 py-12 mt-64 w-full bg-gray-900 max-md:px-5 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col items-center mt-11 mb-11 w-full max-w-[1385px] max-md:my-10 max-md:max-w-full">
          <div className="self-stretch max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:items-stretch">
              <div className="flex flex-col items-stretch w-[55%] max-md:ml-0 max-md:w-full">
                <div className="flex flex-col grow items-stretch max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-5 justify-between items-stretch px-px max-md:flex-wrap max-md:max-w-full">
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/47112b04-838e-419d-8aa3-57f9673f0b86?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                      className="object-contain object-center shrink-0 max-w-full aspect-[1.59] w-[124px]"
                    />
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/0e808dff21d66d7677094cfd97481dc9fab21562ef4532b63181de4353f2fc0a?apiKey=73db546a11a0445f8ca73fcb518d621e&"
                      className="object-contain object-center my-auto aspect-[12.5] w-[416px]"
                    />
                  </div>
                  <div className="mt-14 max-md:mt-10 max-md:max-w-full">
                    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:items-stretch">
                      <div className="flex flex-col items-stretch w-[36%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col items-stretch py-0.5 pr-1.5 text-xl text-white max-md:mt-10">
                          <div className="text-3xl font-semibold leading-8 text-indigo-500">
                            Hair Loss
                            <br />
                            Marketing
                          </div>
                          <div className="mt-3 leading-[105%]">About Us</div>
                          <div className="mt-5 whitespace-nowrap leading-[105%]">
                            Case Studies
                          </div>
                          <div className="mt-5 leading-[105%]">Pricing</div>
                        </div>
                      </div>
                      <div className="flex flex-col items-stretch ml-5 w-[38%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col grow items-stretch py-0.5 text-xl text-white max-md:mt-10">
                          <div className="text-3xl font-semibold leading-8 text-indigo-500">
                            Window
                            <br />
                            Shopping?
                          </div>
                          <div className="mt-2 leading-5 max-md:mr-1">
                            Getting a<br />
                            New Start
                          </div>
                          <div className="mt-5 leading-5 max-md:mr-1">
                            Grow Your
                            <br />
                            Practice
                          </div>
                          <div className="mt-5 leading-5 max-md:mr-1">
                            Lead Your
                            <br />
                            Market
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-stretch ml-5 w-[26%] max-md:ml-0 max-md:w-full">
                        <div className="flex flex-col items-stretch py-1 text-xl text-white max-md:mt-10">
                          <div className="text-3xl font-semibold leading-8 text-indigo-500">
                            Legal
                            <br />
                            Matters
                          </div>
                          <div className="mt-4 leading-5 max-md:mr-1">
                            Cookies
                            <br />
                            Policy
                          </div>
                          <div className="mt-5 leading-5 max-md:mr-1">
                            Terms of <br />
                            Service
                          </div>
                          <div className="mt-5 leading-[105%] max-md:mr-1">
                            Opt Out
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-stretch ml-5 w-[45%] max-md:ml-0 max-md:w-full">
                <div className="self-stretch my-auto max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:items-stretch">
                    <div className="flex flex-col items-stretch w-[45%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col grow items-stretch text-3xl font-semibold text-indigo-500 max-md:mt-10">
                        <div className="whitespace-nowrap leading-[107%]">
                          Got Questions?
                        </div>
                        <div className="justify-center items-center px-16 py-5 mt-5 text-xl font-bold text-center text-white whitespace-nowrap border border-white border-solid leading-[72px] rounded-[57px] max-md:px-5">
                          Call Us
                        </div>
                        <div className="justify-center items-stretch px-6 py-4 mt-4 text-xl font-bold text-center text-white whitespace-nowrap border border-white border-solid leading-[72px] rounded-[57px] max-md:px-5">
                          Book a Meeting
                        </div>
                        <div className="mt-12 leading-[107%] max-md:mt-10">
                          Headquarters
                        </div>
                        <div className="mt-3.5 text-xl leading-7 text-white">
                          145 E Sunrise Highway
                          <br />
                          Suite #1
                          <br />
                          Lindenhurst, NY 11757
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-stretch ml-5 w-[55%] max-md:ml-0 max-md:w-full">
                      <div className="flex flex-col items-stretch text-xl text-white max-md:mt-10">
                        <div className="text-3xl font-semibold leading-8 text-indigo-500">
                          Contact Us
                        </div>
                        <div className="mt-5 leading-[140%]">
                          (631) 867 0900
                        </div>
                        <div className="mt-4 leading-[140%]">
                          info@hairlossmarketing.com
                        </div>
                        <div className="mt-28 text-3xl font-semibold leading-8 text-indigo-500 max-md:mt-10">
                          Office Hours
                        </div>
                        <div className="mt-6 leading-[140%]">
                          M -F 9am - 5pm
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4 justify-center items-stretch mt-52 max-w-full w-[216px] max-md:mt-10">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/ace3fbb132b8a58d59d9cd37f9aa78651552171ff62fe69a7512518b21b0a0a0?apiKey=73db546a11a0445f8ca73fcb518d621e&"
              className="object-contain object-center flex-1 shrink-0 w-full aspect-square fill-indigo-500"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3d10405246ca671ba2629e815705b66b7efa604d839a1f95efbdc27d6b1b134c?apiKey=73db546a11a0445f8ca73fcb518d621e&"
              className="object-contain object-center flex-1 shrink-0 w-full aspect-[0.97]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/963c731fbc73a55345411eb625d736245a924ca446ad3e0b4e0e75417a366ef4?apiKey=73db546a11a0445f8ca73fcb518d621e&"
              className="object-contain object-center flex-1 shrink-0 w-full aspect-[0.97]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/b018b306f153604c8dd6f0dfed0f7e41a930ae787f2bd7e7050cc6f70ac2b2a3?apiKey=73db546a11a0445f8ca73fcb518d621e&"
              className="object-contain object-center flex-1 shrink-0 my-auto w-full aspect-[1.37]"
            />
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/2131e3971704c0875b5e82ee4883b567b375452389240b7538c387305f742878?apiKey=73db546a11a0445f8ca73fcb518d621e&"
              className="object-contain object-center flex-1 shrink-0 w-full aspect-square"
            />
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/9c154ebd4051f350d405d30f8f07d7d76e0eee7858a3b175346e2b3e2e004ae4?apiKey=73db546a11a0445f8ca73fcb518d621e&"
            className="object-contain object-center mt-5 max-w-full aspect-[100] stroke-[1px] stroke-white w-[134px]"
          />
          <div className="mt-4 text-lg text-white whitespace-nowrap">
            Ⓒ Hair Loss Marketing. 2024. All Rights Reserved.
          </div>
        </div>
      </div>
    </div>
  );
}


export default LeadForecastForm;
