// Navigation.js
import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav>
      <ul>
        {/* <li><Link to="/forecast">Forecast Form</Link></li> */}
        {/* Add more navigation links as needed */}
      </ul>
    </nav>
  );
};

export default Navigation;
